
































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import Pagination from "@/components/pagination/Pagination.vue";
import { Table as ElTable } from "element-ui";
import Sortable from "sortablejs"; // 用于拖拽排序
import { IPanelData } from "@/data/commonModel";
import {
  IWineryAfterNoonTeaModel,
  IWineryListQuery,
  IWinerySortReqModel,
} from "../data/wineryModel";
import { WineryAfternoonTeaModule } from "@/store/modules/wineryAfternoonTea";
import {
  getWineryAfterNoonTea,
  updateWineryAfterNoonTeaPublishStatus,
  deleteWineryAfterNoonTea,
  deleteWinery,
  updateWineryAfterNoonTeaListSort,
  getWineryDetails,
} from "@/remote/winery";
import { getWineryInfo } from "@/remote/common";
import { addClass, removeClass } from "@/utils/tools";

@Component({
  components: {
    Pagination,
  },
})
export default class extends Vue {
  @Prop({
    required: true,
  })
  panelObj!: IPanelData;
  // 开始/取消前端显示排序
  @Prop({ default: false })
  isOrdering!: boolean;

  columnLableWidth: string = "110px";
  smallColumnLableWidth: string = "100px";
  titleColumnLableWidth: string = "180px";

  listLoading: boolean = false;
  list: IWineryAfterNoonTeaModel[] = [];
  totalSize = 0;
  listQuery: IWineryListQuery = {
    status: "all",
    updatedTime: "",
  };
  sortable: any = null;
  maxPresentationPages: Number = 6;

  @Watch("panelObj", {
    deep: true,
    immediate: true,
  })
  _onPanelObjChange(value: IPanelData) {
    this.listQuery.status = value.type;
    this.listQuery.title = value.searchText;
    this._fetchWineryRecords({
      ...this.listQuery,
    });
  }

  @Watch("isOrdering", {
    immediate: true,
  })
  onIsOrderingChange(value: boolean) {
    if (this.sortable) {
      this.sortable.option("disabled", !value);
    }
    if (value && this.listQuery.updatedTime !== "") {
      // console.log("清除创建、发布时间排序");
      // 清除创建、发布时间排序
      this.listQuery.updatedTime = "";
      const table = this.$refs["winery-table"] as ElTable;
      table.clearSort();
      this.refreshTable();
    } else if (this.sortable && !value) {
      // 保存排序
      this._updateTableSort();
    }
  }

  mounted() {
    this._rowDrop();
    this._getMaxPresentationPages();
  }

  public refreshTable() {
    this._fetchWineryRecords({
      ...this.listQuery,
    });
  }

  async _getMaxPresentationPages() {
    try {
      const res = await getWineryInfo();
      if (res && res.data && res.data.data) {
        const { maxPresentationPages } = res.data.data;
        this.maxPresentationPages = maxPresentationPages;
      }
    } catch {
      // console.log("_getMaxPresentationPages 出错了");
    }
  }

  async _fetchWineryRecords(params: IWineryListQuery) {
    this.listLoading = true;
    try {
      const res = await getWineryAfterNoonTea(params);
      console.log("data");
      console.log(res);
      if (res && res.data && res.data.data) {
        const { data } = res.data;
        console.log("1");
        console.log(data);
        data.forEach((element: IWineryAfterNoonTeaModel) => {
          element.updatedAtText = moment(element.updatedAt).format(
            "YYYY-MM-DD"
          );
          console.log("2");
          element.details.coverImageUrl = [
            { path: element.details.coverImageUrl },
          ];
          console.log("3");
          if (element.details.detailCoverImageUrls) {
            const arr: any = [];
            element.details.detailCoverImageUrls.forEach((image: string) => {
              arr.push({ path: image });
            });
            element.details.detailCoverImageUrls = arr;
          } else {
            element.details.detailCoverImageUrls = [];
          }
          console.log("2");
          if (element.details.detailContentImageUrls) {
            const arr: any = [];
            element.details.detailContentImageUrls.forEach((image: string) => {
              arr.push({ path: image });
            });
            element.details.detailContentImageUrls = arr;
          } else {
            element.details.detailContentImageUrls = [];
          }
          console.log("element");
          console.log(element);

          // element.titleForList =
          //   element.title.length === 0 ? "无" : element.title;
          // element.subTitleForList =
          //   element.subtitle.length === 0 ? "无" : element.subtitle;
        });
        this.list = data;
      } else {
        this.list = [];
      }
    } catch (error) {
      // console.log(error);
      // console.log("getWineryRecords 出错了");
    }
    console.log("error");
    console.log(this.list);
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  _onSortChange(column: any) {
    const { order, prop } = column;
    if (!order) {
      this.listQuery.updatedTime = "";
    } else if (order === "descending") {
      this.listQuery.updatedTime = "DESC";
    } else {
      this.listQuery.updatedTime = "ASC";
    }
    this.refreshTable();
  }

  async _onClickEdit(id: number) {
    console.log("_onClickEdit");
    console.log(id);
    this.listLoading = true;
    try {
      this.$emit("onEditWinery", this.list[id]);
      this.listLoading = false;
    } catch {
      this.listLoading = false;
      // console.log("getWineryDetails 失败了");
    }
  }

  _onChangeSwitch(value: boolean, index: number) {
    this.$confirm("", value ? "确认发布这个下午茶吗？" : "确认取消发布吗？", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      center: true,
    })
      .then(() => {
        this._onUpdatePublishStatus(value, index);
      })
      .catch(() => {
        this.list[index].isPublished = !value;
      });
  }

  async _onUpdatePublishStatus(value: boolean, index: number) {
    try {
      this.listLoading = true;

      const res = await updateWineryAfterNoonTeaPublishStatus(
        this.list[index].wineryId,
        this.list[index].id,
        value
      );
      this.listLoading = false;
      if (res.data.resultCode === 0) {
        if (this.listQuery.status === "all") {
          this.list[index].isPublished = value;
        } else {
          this.refreshTable();
        }
        this.$message.success("操作成功");
        WineryAfternoonTeaModule.FetchWineryCountByStatus();
      }
    } catch {
      this.listLoading = false;
    }
  }

  // 删除
  _onClickDelete(id: string) {
    this.$confirm("", "确认删除这个下午茶吗？", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      center: true,
    })
      .then(() => {
        this._deleteWinery(id);
      })
      .catch(() => {});
  }

  async _deleteWinery(id: string) {
    try {
      this.listLoading = true;
      const res = await deleteWineryAfterNoonTea(id);
      this.listLoading = false;
      if (res && res.status === 200) {
        this._fetchWineryRecords(this.listQuery);
        this.$message.success("操作成功");
        WineryAfternoonTeaModule.FetchWineryCountByStatus();
      }
    } catch {
      this.listLoading = false;
    }
  }

  //行拖拽
  _rowDrop() {
    const tbody = document.querySelector(".el-table__body-wrapper tbody");
    const _this = this;
    // console.log("tbody", tbody);
    if (!tbody) return;
    this.sortable = new Sortable(tbody, {
      disabled: true,
      onStart: () => {
        addClass(".el-table__body-wrapper tbody", "cursor-move");
        removeClass(".el-table__body-wrapper tbody", "cursor-default");
      },
      onEnd: (event: any) => {
        const { newIndex, oldIndex } = event;
        const currRow = _this.list.splice(oldIndex, 1)[0]; // 删除
        _this.list.splice(newIndex, 0, currRow); // 添加到指定位置
        removeClass(".el-table__body-wrapper tbody", "cursor-move");
        addClass(".el-table__body-wrapper tbody", "cursor-default");
      },
    });
  }

  // 更新展示排序
  async _updateTableSort() {
    const arr: String[] = [];
    this.list.forEach((element: IWineryAfterNoonTeaModel, index: number) => {
      arr.push(element.id);
    });
    try {
      this.listLoading = true;
      const res = await updateWineryAfterNoonTeaListSort(arr);
      if (res && res.status === 200) {
        this.$message.success("保存成功");
      } else {
        this.$message.error("保存失败，请重试");
      }
      this.listLoading = false;
    } catch {
      this.listLoading = false;
      this.$message.error("保存失败，请重试");
      // console.log("updateWineryListSort 出错了");
    }
  }
}
