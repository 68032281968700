


































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ImgUpload from "@components/ImgUpload.vue";
import { Form as ElForm } from "element-ui";
import { IMediaModel } from "@/data/commonModel";
import {
  addWineryAfterNoonTea,
  updateWineryAfterNoonTea,
} from "@/remote/winery";
import { IWineryAfterNoonTeaModel } from "../data/wineryModel";

interface IWineryFormModel {
  title: string;
  subTitle: string;
  introductionMedia: IMediaModel[];
}

// 装饰器模式
@Component({
  components: {
    ImgUpload,
    ElForm,
  },
})
export default class extends Vue {
  // 显示或隐藏
  @Prop({
    default: false,
  })
  dialogFormVisible!: boolean;
  // 编辑类型
  @Prop({
    default: "add",
  })
  editType!: string;
  // editType = 'edit'时所需要的数据
  @Prop() wineryModel!: IWineryAfterNoonTeaModel;

  // Data
  formLabelWidth: string = "140px";
  formValueWidth: number = 450;
  formParams: IWineryAfterNoonTeaModel = {
    id: "",
    wineryId:"",
    type: "afternoon_tea",
    title: "",
    sku:'',
    price: 0,
    isPublished: false,
    updatedAt: "",
    updatedAtText: "",
    details: {
      coverImageUrl: [],
      detailCoverImageUrls: [],
      detailContentImageUrls: [],
      buyNowButtonText: "",
      popupTitle: "",
      items: [
        {
          title: "",
          description: "",
          titleLength: 0,
        },
      ],
      labels: ["酒庄预约专享"],
    },
  };

  wineryId: string = "";
  saveBtnLoading: boolean = false;
  titleLength: number = 0;
  popupTitleLength: number = 0;
  buyNowButtonTextLength: number = 0;
  detailTitles: string[] = [""];
  detailQuantity: string[] = [""];
  // Rules
  rules = {
    //   coverImageUrl: {
    //   type: "array",
    //   required: true,
    //   trigger: "focus",
    //   message: "请选择图片",
    // },
    //    title: {
    //   type: "string",
    //   required: true,
    //   trigger: "blur",
    //   message: "请输入商品名称",
    // },
    //  price: {
    //   type: "number",
    //   required: true,
    //   trigger: "blur",
    //   message: "请输入商品单价",
    // },
    // detailContentImageUrl: {
    //   type: "array",
    //   required: true,
    //   trigger: "focus",
    //   message: "请选择图片",
    // },
    //  popupTitle: {
    //   type: "number",
    //   required: true,
    //   trigger: "blur",
    //   message: "请输入弹窗页标题",
    // },
    //  buyNowButtonText: {
    //   type: "number",
    //   required: true,
    //   trigger: "blur",
    //   message: "请输入按钮文案",
    // },
  };

  // Computed
  get uploadImageItemWidth() {
    return `${(this.formValueWidth - 20) / 5}px`;
  }

  // Watch
  @Watch("wineryModel", {
    immediate: true,
    deep: true,
  })
  onWineryModelChange(value: IWineryAfterNoonTeaModel) {
    if (!value) return;
    console.log("onWineryModelChange");
    console.log(value);
    const model = JSON.parse(JSON.stringify(value));
    this.wineryId = model.id;

    this.formParams = value;
    // this.titleLength=this.getStringLength( this.formParams.title);
    //  this.buyNowButtonTextLength=this.getStringLength( this.formParams.details.buyNowButtonText);
    //  this.popupTitleLength=this.getStringLength( this.formParams.details.popupTitle);
    // this.formParams.details.items.forEach(element=>{
    //   element.titleLength=this.getStringLength( element.title);
    // })
    // this.formParams.details.items.push({
    //   title: "",
    //   description: "",
    //   titleLength: 0,
    // });
  }

  // Methods
  _beforeModalClose() {
    this.$emit("update:dialogFormVisible", false); // 直接修改父组件的属性
  }

  _hide() {
    this.$emit("update:dialogFormVisible", false);
  }
_onChangeSwitch(isOpen:boolean){
  if(isOpen){
    this.formParams.details.labels=["酒庄预约专享"]
  }else{
    this.formParams.details.labels=[]
  }
}
  _onInput(event: any, type: string) {
    console.log(type);
    console.log(event);
    var value = event.target.value.trimStart();
    var temp = 0;
    var maxLen = type === "title" ? 20 : 16;

    if (value.length === 0 && type === "title") {
      this.titleLength = 0;
    }

    for (var i = 0; i < value.length; i++) {
      if (/[\u4e00-\u9fa5]/.test(value[i])) {
        temp += 2;
      } else {
        temp++;
      }
      if (temp > maxLen) {
        // @ts-ignore
        value = value.substr(0, i).trimStart();
      }
    }
    if (type === "title") {
      console.log("is title");
      console.log(value);
      this.formParams.title = value;
      console.log(this.formParams.title);
      this.titleLength = temp > maxLen ? maxLen : temp;
    }
    // else if (type === "buyNowButtonText") {
    //   this.formParams.details.buyNowButtonText = value;
    //   this.popupTitleLength = temp > maxLen ? maxLen : temp;
    // }
    // else {
    //   this.formParams.details.popupTitle = value;
    //   this.buyNowButtonTextLength = temp > maxLen ? maxLen : temp;
    // }
    console.log(this.formParams);
  }
  addItems() {
    console.log("addItems");
    this.formParams.details.items.push({
      title: "",
      description: "",
      titleLength: 0,
    });
  }
  getStringLength(value:string){
    let temp =0;
for (var i = 0; i < value.length; i++) {
      if (/[\u4e00-\u9fa5]/.test(value[i])) {
        temp += 2;
      } else {
        temp++;
      }
    }
    return temp;
  }
  onChange(value: string, type: string, index: number) {
    console.log(value);
    console.log(type);
    console.log(index);
    // var value = data.trimStart();
    var temp = 0;
    var maxLen = 8;
    if (type === "item-name") {
      maxLen = 8;
    } else if (type === "popupTitle") {
      maxLen = 8;
    } else if (type === "buyNowButtonText") {
      maxLen = 8;
    } else if (type === "title") {
      maxLen = 21;
    }
    console.log(maxLen);
    for (var i = 0; i < value.length; i++) {
      if (/[\u4e00-\u9fa5]/.test(value[i])) {
        temp += 2;
      } else {
        temp++;
      }
      if (temp > maxLen) {
        // @ts-ignore
        value = value.substr(0, i).trimStart();
        break;
      }
    }
    if (type === "item-name") {
      this.formParams.details.items[index].title = value;
      this.formParams.details.items[index].titleLength =
        temp > maxLen ? maxLen : temp;
    } else if (type === "popupTitle") {
      this.formParams.details.popupTitle = value;
      this.popupTitleLength = temp > maxLen ? maxLen : temp;
    } else if (type === "buyNowButtonText") {
      this.formParams.details.buyNowButtonText = value;
      this.buyNowButtonTextLength = temp > maxLen ? maxLen : temp;
    } else if (type === "title") {
      console.log(this.formParams.title)
      this.formParams.title = value;
      this.titleLength = temp > maxLen ? maxLen : temp;
    }
  }
  _calcTextLength(value: string): number {
    let temp = 0;
    for (var i = 0; i < value.length; i++) {
      if (/[\u4e00-\u9fa5]/.test(value[i])) {
        temp += 2;
      } else {
        temp++;
      }
    }
    return temp;
  }

  _onClickConfirm() {
     if(this.formParams.sku.length==0){
      this.$message.error("请输入产品sku");
      return;
    }
     if(this.formParams.details.coverImageUrl.length===0){
      this.$message.error("请设置列表页图片");
      return;
    }
    const form = this.$refs["wineryForm"] as ElForm;
    if(this.formParams.title.length===0){
      this.$message.error("请输入商品名称");
      return;
    }
    if(this.formParams.price===0){
      this.$message.error("请输入产品价格");
      return;
    }
    if(this.formParams.details.detailCoverImageUrls.length===0){
      this.$message.error("请设置详情页图片");
      return;
    }
    if(this.formParams.details.detailContentImageUrls.length===0){
      this.$message.error("请设置详情页图片详情");
      return;
    }
    if(this.formParams.details.buyNowButtonText.length===0){
      this.$message.error("请设置详情页按钮文案");
      return;
    }
     if(this.formParams.details.popupTitle.length===0){
      this.$message.error("请设置弹窗标题");
      return;
    }
    if(this.formParams.details.items.length===0){
      this.$message.error("请设置弹窗内容列表");
      return;
    }
    form.validate((valid: Boolean) => {
      if (valid) {
        this._showConfirmToast();
      } else {
        return false;
      }
    });
  }

  _showConfirmToast() {
    this.$confirm(
      this.editType === "edit"
        ? "确认更新该下午茶吗？"
        : "确认保存为新的下午茶吗？",
      "提示",
      {
        confirmButtonText: this.editType === "edit" ? "更新" : "保存",
        cancelButtonText: "取消",
        center: true,
      }
    )
      .then(() => {
        this._handleFormData();
      })
      .catch(() => {});
  }

  _onClickCancel() {
    this.$confirm("", "确认放弃编辑吗？", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      center: true,
    })
      .then(() => {
        this._hide();
      })
      .catch(() => {});
  }

  // 处理表单数据
  _handleFormData() {
    console.log("_handleFormData  33"); 

    this.formParams.price = Number.parseFloat(this.formParams.price + "");
    console.log(this.formParams);
    this.saveBtnLoading = true;
    if (this.editType === "edit") {
      this._updateWinery(this.formParams);
    } else {
      this._createWinery(this.formParams);
    }
  }

  // 新增
  async _createWinery(reqModel: IWineryAfterNoonTeaModel) {
    try {
     
      const res = await addWineryAfterNoonTea(reqModel);
      if (res.status === 200) {
        this.$message.success("保存成功");
        this.$emit("onEditWinerySuccess", this.editType);
        this._hide();
      }
      this.saveBtnLoading = false;
    } catch {
      this.saveBtnLoading = false;
      // console.log("_createWinery出错了");
    }
  }

  // 编辑
  async _updateWinery(reqModel: IWineryAfterNoonTeaModel) {
    try {
       console.log("_createWinery")
      console.log(reqModel)
      const res = await updateWineryAfterNoonTea(this.wineryId, reqModel);
      if (res.status === 200) {
        this.$message.success("保存成功");
        this.$emit("onEditWinerySuccess", this.editType);
        this._hide();
      }
      this.saveBtnLoading = false;
    } catch {
      this.saveBtnLoading = false;
      // console.log("_updateWinery 出错了");
    }
  }
}
