






































import { Component, Vue } from "vue-property-decorator";
import PanelGroup from "@/components/PanelGroup.vue";
import WineryTable from "./components/WineryTable.vue";
import WineryEdit from "./components/WineryEdit.vue";
import { ITabModel, IPanelData } from "@/data/commonModel";
import { IWineryAfterNoonTeaModel } from "./data/wineryModel";
import { WineryAfternoonTeaModule } from "@/store/modules/wineryAfternoonTea";

@Component({
  components: {
    PanelGroup,
    WineryTable,
    WineryEdit,
  },
})
export default class extends Vue {
  editDialogVisible: boolean = false;
  isOrdering: boolean = false; // 是否在排序中
  editType: string = "add";
  panelObj: IPanelData = { type: "all", searchText: "",show:false };
  wineryModel: IWineryAfterNoonTeaModel | null = null;

  get tabs(): ITabModel[] {
    const arr = [
      {
        title: `全部(${WineryAfternoonTeaModule.WineryAfternoonTeaCountRes.total})`,
        key: "all",
      },
      {
        title: `已发布(${WineryAfternoonTeaModule.WineryAfternoonTeaCountRes.published})`,
        key: "published",
      },
      {
        title: `未发布(${WineryAfternoonTeaModule.WineryAfternoonTeaCountRes.draft})`,
        key: "unpublished",
      },
    ];
    return arr;
  }
  mounted() {
    WineryAfternoonTeaModule.FetchWineryCountByStatus();
  }

  _handleSwitchTab(props: IPanelData) {
    this.panelObj = props;
    this.isOrdering = false;
  }

  _onClickAdd() {
    this.editType = "add";
    this.wineryModel = null;
    this.editDialogVisible = true;
  }

  // 开始/取消排序
  _onClickSort() {
    this.isOrdering = !this.isOrdering;
  }

  _handleEditWinerySuccess(type: string) {
    WineryAfternoonTeaModule.FetchWineryCountByStatus();
    const ref: WineryTable = this.$refs["winery-table"] as WineryTable;
    ref.refreshTable();
  }

  _handleEditWinery(value: IWineryAfterNoonTeaModel) {
    this.editType = "edit";
    this.editDialogVisible = true;
    this.wineryModel = JSON.parse(JSON.stringify(value));
  }
}
