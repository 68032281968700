import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action
} from 'vuex-module-decorators'
import store from '@/store'
import { IWineryAfternoonTeaCountRes } from '@/pages/winery/data/wineryModel'
import { getWineryAfternoonTeaCountByStatus } from '@/remote/winery'

export interface IWineryState {
  WineryAfternoonTeaCountRes: IWineryAfternoonTeaCountRes
}

@Module({ dynamic: true, store, name: 'WineryAfternoonTea' })
class WineryAfternoonTea extends VuexModule implements IWineryState {
  public WineryAfternoonTeaCountRes = {
    total: 0,
    published: 0,
    draft: 0
  }

  @Action
  public async FetchWineryCountByStatus() {
    try {
      const res = await getWineryAfternoonTeaCountByStatus()
      console.log('getWineryAfternoonTeaCountByStatus')
      if (res && res.data && res.data.data) {
        this.SET_WINERY_AfternoonTea_COUNT_RES(res.data.data)
      }
    } catch {
      console.log('getWineryAfternoonTeaCountByStatus')
    }
  }

  @Mutation
  SET_WINERY_AfternoonTea_COUNT_RES(res: IWineryAfternoonTeaCountRes) {
    this.WineryAfternoonTeaCountRes = res
  }
}

export const WineryAfternoonTeaModule = getModule(WineryAfternoonTea)
